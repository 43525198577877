import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const ChatWindow = styled.div`
  font-size: 16px;
  font-weight: bold;

  margin-right: 5px;

  .bot-heading {
    font-size: 18px;
    background: black;
    height: 50px;
    line-height: 50px;
    color: white;
    font-weight: bold;
    padding-left: 15px;
  }

  #chatbot {
    line-height: 1.5rem;

    p {
      font-weight: 400;
    }
  }

  .webchat__suggested-action {
    cursor: pointer;
  }

  .webchat__suggested-action:hover {
    background-color: white;
    color: black;
  }

  .webchat__imageAvatar__image {
    border-radius: 9999px;
    border: 1px solid black;
  }
  border: grey solid 1px;
`;

export const ChatButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
  width: 90px;
`;

export const Wrapper = styled.div`
  display: none;
  position: fixed;
  right: 50px;
  bottom: 100px;
  z-index: 102;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: flex-start; */
  gap: 10px;
  width: fit-content;
  filter: drop-shadow(0px 5px 3px #00ffc5 8c);
  outline: none;

  ${breakpoints.mobile} {
    bottom: 15px;
    right: 15px;
  }
`;
